import React from "react";
import styled from "styled-components";

import { withPrefix } from "gatsby";

import { DATA } from "../data";
import { MOBILE_BREAKPOINT } from "../style";

const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 24px;
  opacity: 0.5;
  transition: all ease 0.5s;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    &:first-child {
      margin-left: 0;
    }
  }
`;

const Social = styled.div`
  display: flex;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    justify-content: flex-end;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-content: flex-start;
  }
`;

const EndWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    align-items: flex-end;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    align-items: flex-start;
    margin-top: 32px;
  }
`;

const Copyright = styled.p`
  font-size: 12px;
  color: white;
  opacity: 0.7;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 24px;
  }
`;

const Info = styled.p`
  flex-grow: 1;
  min-width: 196px;
  color: white;
  font-size: 14px;
  line-height: 32px;
  color: #bebebe;

  a {
    margin-left: 8px;
    color: ${DATA.color};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 16px 0;
  }
`;

const TextLogo = styled.img`
  width: 96px;
  filter: invert(100%);
`;

const Logo = styled.img`
  width: 48px;
  margin-bottom: 24px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 64px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  background-color: #101010;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    padding: 128px 0 64px 0;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 0 64px 0;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 48px;
    flex-direction: row;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 32px;
    flex-direction: column;
  }
`;

const SocialLink = styled.a``;

export class Footer extends React.Component {
  render() {
    const social = [
      {
        icon: withPrefix(`/${DATA.logoLinkLinkedIn}`),
        link: DATA.social.linkedin
      },
      {
        icon: withPrefix(`/${DATA.logoLinkEmail}`),
        link: `mailto:${DATA.contact.email}`
      },
      {
        icon: withPrefix(`/${DATA.logoLinkTwitter}`),
        link: DATA.social.twitter
      }
    ];

    return (
      <FooterWrapper id="Contact">
        <FooterContent>
          <LogoWrapper>
            <Logo src={withPrefix(`/${DATA.logoBw}`)} />
            <TextLogo src={withPrefix(`/${DATA.textLogo}`)} />
          </LogoWrapper>
          <Info>
            {DATA.addresses.office.map((t, k) => (
              <span key={k}>
                {t}
                <br />
              </span>
            ))}
          </Info>
          <Info>
            <span>Tel: {DATA.contact.number}</span>
            <br /> <span>Email: </span>
            <a href={`mailto:${DATA.contact.email}`}>{DATA.contact.email}</a>
            <br />
            <span>Impressum: </span> <a href="/impressum">View</a>
          </Info>
          <EndWrapper>
            <Social>
              {social.map((s, k) => (
                <SocialLink key={k} href={s.link}>
                  <SocialIcon src={s.icon} />
                </SocialLink>
              ))}
            </Social>
            <Copyright>&copy; 2015-2024 {DATA.companyName}</Copyright>
          </EndWrapper>
        </FooterContent>
      </FooterWrapper>
    );
  }
}
